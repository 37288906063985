import { useCallback } from 'react';

import { useQuery } from '../hooks';

import { CMS_PAGE_CONTENT_SLOTS } from '../../graphql/cms-queries';

function useCmsPageContentSlots(
  contentPageId: string,
  shouldSkipQuery = false
) {
  const { data: cmsData } = useQuery(CMS_PAGE_CONTENT_SLOTS, {
    skip: shouldSkipQuery,
    variables: {
      id: contentPageId
    }
  });

  const { contentSlots } = cmsData?.cms?.page || {};

  const getContentSlot = useCallback(
    (name: string = '') =>
      contentSlots?.filter(contentSlot => contentSlot.name === name)?.[0]
        ?.cmsComponents?.[0]?.html,
    [contentSlots]
  );

  return {
    getContentSlot
  };
}

export default useCmsPageContentSlots;
