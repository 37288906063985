import React from 'react';

import AltButton from '../../../../library/alt-button/alt-button';
import AltButtonGroup from '../../../../library/alt-button/components/alt-button-group/alt-button-group';
import {
  CalendarAltSolidIcon,
  ExchangeAltSolidIcon,
  LocationArrowSolidIcon,
  PhoneSolidIcon,
  ShareAltSolidIcon
} from '../../../../shared/icons/icons';

import {
  useModal,
  useScheduleAppointment
} from '../../../../shared/hooks/hooks';

import { getGoogleMapsLink } from '../../../../shared/utils/store/store';

import {
  DtAddressData,
  GeoPoint,
  Maybe,
  StoreReviewRatingData
} from '../../../../shared/types/graphql-types';
import { SHARE_STORE } from '../../../../shared/constants/modals';

interface StoreQuickViewAltCtasProps {
  address: DtAddressData;
  distance?: number;
  geoPoint?: Maybe<GeoPoint>;
  legacyStoreCode?: string;
  onChangeStore?: (changeStore: boolean, storeCode: string) => void;
  onGetDirectionsClick?: () => void;
  onScheduleAppointmentClick?: () => void;
  onShareClick?: () => void;
  rating?: Maybe<StoreReviewRatingData>;
  showAltCallStoreCTA?: boolean;
  showAltChangeStoreCTA?: boolean;
  showAltDirectionsCTA?: boolean;
  showAltScheduleAppointmentCTA?: boolean;
  showAltShareStoreCTA?: boolean;
  storeCode: string;
}

function StoreQuickViewAltCtas(props: StoreQuickViewAltCtasProps) {
  const {
    address,
    distance,
    geoPoint,
    legacyStoreCode,
    onChangeStore,
    onGetDirectionsClick,
    onScheduleAppointmentClick,
    onShareClick,
    rating,
    showAltCallStoreCTA,
    showAltChangeStoreCTA,
    showAltDirectionsCTA,
    showAltScheduleAppointmentCTA,
    showAltShareStoreCTA,
    storeCode
  } = props;
  const { openModal } = useModal();
  const { handleScheduleAppointment } = useScheduleAppointment();
  const { phone } = address || {};

  const showAltCtas =
    showAltDirectionsCTA ||
    showAltScheduleAppointmentCTA ||
    showAltShareStoreCTA ||
    showAltChangeStoreCTA;

  function onDirectionsClick() {
    const googleMapsLink = getGoogleMapsLink(address, geoPoint);
    onGetDirectionsClick?.();

    if (googleMapsLink) {
      return window.open(googleMapsLink, '_blank');
    }
  }

  return showAltCtas ? (
    <AltButtonGroup>
      {showAltDirectionsCTA && (
        <AltButton icon={LocationArrowSolidIcon} onClick={onDirectionsClick}>
          Directions
        </AltButton>
      )}

      {showAltScheduleAppointmentCTA && (
        <AltButton
          icon={CalendarAltSolidIcon}
          onClick={() => {
            handleScheduleAppointment(storeCode);
            onScheduleAppointmentClick?.();
          }}
        >
          Appointment
        </AltButton>
      )}

      {showAltShareStoreCTA && (
        <AltButton
          icon={ShareAltSolidIcon}
          onClick={() => {
            onShareClick?.();
            openModal(SHARE_STORE, {
              store: {
                address,
                code: storeCode,
                distance,
                legacyStoreCode,
                rating
              }
            });
          }}
        >
          Share
        </AltButton>
      )}

      {showAltChangeStoreCTA && (
        <AltButton
          icon={ExchangeAltSolidIcon}
          onClick={() => onChangeStore?.(true, storeCode)}
        >
          Change Store
        </AltButton>
      )}
      {showAltCallStoreCTA && phone && (
        <AltButton
          icon={PhoneSolidIcon}
          onClick={() =>
            (window.location.href = `tel:${`${phone}`.replace(/\D/g, '')}`)
          }
        >
          Call Store
        </AltButton>
      )}
    </AltButtonGroup>
  ) : null;
}

export default StoreQuickViewAltCtas;
