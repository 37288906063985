import React from 'react';

import Typography from '../../../../library/typography/typography';
import PitPassLogo from '../../../../components/pitpass-logo/pitpass-logo';

import { Maybe } from '../../../../shared/types/graphql-types';

import './store-quick-view-header.scss';

interface StoreQuickViewHeaderProps {
  isMyStore: boolean;
  myStoreTitle?: Maybe<string>;
  showPitPassLogo: boolean;
}

function StoreQuickViewHeader(props: StoreQuickViewHeaderProps) {
  const {
    isMyStore,
    myStoreTitle = 'My Selected Store',
    showPitPassLogo
  } = props;

  if (!isMyStore) {
    return null;
  }

  return (
    <div styleName="container">
      {isMyStore && (
        <Typography
          className="store-quick-view-header__title"
          styleName="title"
          tag="span"
          variant="H6"
          weight="black"
        >
          {myStoreTitle}
        </Typography>
      )}

      {showPitPassLogo && (
        <div styleName="pitpass-logo">
          <PitPassLogo isPitPassLogoOnly />
        </div>
      )}
    </div>
  );
}

export default StoreQuickViewHeader;
