import React from 'react';
import { SingleValueProps } from 'react-select';

interface SelectValueWrapperProps extends SingleValueProps {
  component: React.ElementType;
}

function SelectValueWrapper(props: SelectValueWrapperProps) {
  const { className = '', component: Component, getValue, innerProps } = props;
  const selectedValue = getValue()[0];

  return (
    <div className={`react-select__single-value ${className}`} {...innerProps}>
      <Component
        {...(typeof selectedValue === 'object' ? selectedValue : {})}
      />
    </div>
  );
}

export default SelectValueWrapper;
