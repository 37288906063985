import gql from 'graphql-tag';

export const CMS_TOOLTIPS = gql`
  query cmsTooltips($id: String!) {
    cms {
      page(siteId: "discountTire", id: $id) {
        contentSlots {
          cmsComponents {
            ... on CmsToolTipsComponent {
              tooltips {
                id
                tipText
              }
            }
          }
        }
      }
    }
  }
`;

export const CMS_PAGE_CONTENT_SLOTS = gql`
  query cmsPageContentSlots($id: String!) {
    cms {
      page(id: $id) {
        contentSlots {
          name: id
          cmsComponents {
            ... on CmsHtmlComponent {
              html
            }
          }
        }
      }
    }
  }
`;
