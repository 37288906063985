import React, { useMemo } from 'react';

import { useCmsPageContentSlots } from '../../shared/hooks/hooks';

import {
  ATC_MARKETING_MESSAGE,
  LOCATOR_MARKETING_MESSAGE,
  SDP_MARKETING_MESSAGE
} from '../../shared/constants/messages';

import './shorter-wait-time-message.scss';

interface ShorterWaitTimeMessageProps {
  className?: string;
  messageKey:
    | typeof ATC_MARKETING_MESSAGE
    | typeof LOCATOR_MARKETING_MESSAGE
    | typeof SDP_MARKETING_MESSAGE;
}

function ShorterWaitTimeMessage(props: ShorterWaitTimeMessageProps) {
  const { className = '', messageKey } = props;

  const { getContentSlot } = useCmsPageContentSlots('buy-tires');

  const message = useMemo(
    () => getContentSlot(messageKey),
    [getContentSlot, messageKey]
  );

  return (
    <aside
      className={`shorter-wait-time-message ${className}`}
      dangerouslySetInnerHTML={{ __html: message }}
      styleName="container"
    />
  );
}

export default ShorterWaitTimeMessage;
