import React, { PropsWithChildren } from 'react';

import './alt-button-group.scss';

function AltButtonGroup(props: PropsWithChildren<{}>) {
  const { children } = props;
  return (
    <div className="alt-button-group" styleName="container">
      {children}
    </div>
  );
}

export default AltButtonGroup;
